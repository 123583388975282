<template>
  <b-row>
    <b-col class="d-flex justify-content-center align-items-center"
           md="6">
      <b-img
        class="px-50 limit-size"
        fluid block center
        v-bind="{src: photo ? `${url}${photo}` : ''}"
        @click.stop="$emit('click-img', { src: photo ? `${url}${photo}` : '' })"
      ></b-img>
    </b-col>
    <template>
      <b-col class="d-flex flex-column justify-content-start align-items-center gap-2" md="6">
        <p v-if="postcard_text" class="mb-50">
          <feather-icon
            class="order__icon-comment"
            v-bind="{
              title: postcard_text,
              icon: 'MailIcon',
              size: '4x', fill: 'red',
              color:'white',
              cursor: 'pointer'
            }"
            @click.stop="$emit('click-postcard', { text: postcard_text })"
          ></feather-icon>
        </p>
        <p v-if="composition_comment">
          <span v-if="composition_comment.length > max">
            <span> {{ expand ? composition_comment : truncate(composition_comment, max) }} </span>
            <b-button class="text-primary p-0"
                      v-bind="{variant: 'link'}"
                      v-on="{ click: onExpand }"
            >
              {{ expand ? 'Свернуть' : 'Подробнее' }}
            </b-button>
          </span>
          <span v-else> {{ composition_comment }} </span>
        </p>
      </b-col>
    </template>
  </b-row>
</template>

<script>
import { BASE_URL } from '@/config'
import truncate from 'truncate'

export default {
  name: 'CalendarOrderCardImage',

  data() {
    return {
      expand: false,
    }
  },

  props: {
    photo: {
      type: String,
      default() {
        return ''
      },
    },
    postcard_text: {
      type: String,
      default() {
        return ''
      },
    },
    composition_comment: {
      type: String,
      default() {
        return ''
      },
    },
    fluidGrow: {
      type: Boolean,
      default() {
        return true
      },
    },
    url: {
      type: String,
      default() {
        return BASE_URL
      },
    },
    max: {
      type: Number,
      default() {
        return 200
      },
    },
  },

  methods: {
    truncate,
    onExpand(event) {
      event.stopPropagation()

      this.expand = !this.expand
    },
  },
}
</script>

<style scoped>
.limit-size {
  max-width: 240px;
}
</style>
